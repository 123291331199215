import React from "react";
import Layout from "../../../../../other/layout";
import AnnouncerPro from "../../../../../components/AC-StaticPages/Developpez/ServicesWeb/PromotionDeSiteWeb/AnnouncerPro/AnnouncerPro";
import SEO from "../../../../../other/seo";

export default function announcerPro() {
  return (
    <Layout>
      <SEO title="Announcer Pro-Promotion de site Web-Services Web-Développez" />
      <AnnouncerPro />
    </Layout>
  );
}
