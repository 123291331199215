import React from "react";
import styles from "./styles.module.css";
import Button from "../../../../components/Button";
import announcerpro from "../../../../../../assets/img/announcerpro.jpg";

export default function OSCommerce() {
  return (
    <div className={styles.container}>
      <div className={styles.row}>
        <h1>Announcer Pro</h1>
        <p>
          Avec Announcer Pro, il est facile de créer des infolettres courriel
          attrayantes et améliorer ainsi la communication avec votre clientèle.
          Distribuez une infolettre d'entreprise chaque mois ou envoyez «
          l'astuce de la semaine », faites la promotion de vos soldes
          saisonniers ou d'un nouveau produit, bref donnez à vos clients les
          raisons qu'il leur faut pour que votre entreprise, vos produits ou vos
          services demeurent premiers cités.
        </p>
        <img
          src={announcerpro}
          alt="Diffusez votre message avec Announcer Pro!"
        ></img>
        <p>
          Avec des outils de conception graphique fonctionnant par simple
          glisser-déposer ainsi que des modèles professionnels, il est facile de
          créer des infolettres courriel HTML personnalisées. Les outils de
          production de rapports détaillés vous permettent de savoir pour chacun
          de vos courriels quels destinataires l'ont lu, s'ils l'ont fait suivre
          à des amis et quels liens ont été cliqués.
        </p>
      </div>
      <div className={styles.row}>
        <h2>Forfaits Announcer Pro</h2>
        <div className={styles.column_4}>
          <div className={styles.card}>
            <h3>PRO 500</h3>
            <h4>14,99 $</h4>
            <p>Envoyez jusqu’à 500 courriels par mois</p>
          </div>
        </div>
        <div className={styles.column_4}>
          <div className={styles.card}>
            <h3>PRO 1500</h3>
            <h4>26,99 $</h4>
            <p>Envoyez jusqu’à 1500 courriels par mois</p>
          </div>
        </div>
        <div className={styles.column_4}>
          <div className={styles.card}>
            <h3>PRO 2500</h3>
            <h4>34,99 $</h4>
            <p>Envoyez jusqu’à 2500 courriels par mois</p>
          </div>
        </div>
        <div className={styles.column_4}>
          <div className={styles.card}>
            <h3>PRO 5000</h3>
            <h4>57,99 $</h4>
            <p>Envoyez jusqu’à 5000 courriels par mois</p>
          </div>
        </div>
      </div>
      <div className={styles.row}>
        <div className={styles.column_2}>
          <h3>Caractéristiques</h3>
          <ul>
            <li>Outils de conception</li>
            <li>Planification</li>
            <li>Gestion des abus</li>
            <li>Gestion du carnet d'adresses</li>
            <li>Rapports de campagne</li>
          </ul>
        </div>
        <div className={styles.column_2}>
          <h3>Avantages</h3>
          <ul>
            <li>
              Choisissez le concept qui vous plaît dans la banque de modèles
              d'infolettres
            </li>
            <li>
              Entrez-y facilement votre propre contenu, formatez votre texte et
              ajoutez des liens
            </li>
            <li>
              Personnalisez la mise en page en utilisant le formatage par
              glisser-déposer
            </li>
            <li>
              Ajoutez vos propres images ou choisissez-en parmi les 30 000 de
              notre iconothèque libre de droits
            </li>
            <li>Envoyez votre infolettre courriel au format HTML ou RTF</li>
            <li>
              Déterminez à l'avance les dates et heures d'envoi de vos
              infolettres
            </li>
            <li>
              Planifiez plusieurs campagnes courriel destinées à différents
              groupes de destinataires
            </li>
            <li>
              Toutes les infolettres comportent un lien de désabonnement
              obligatoire
            </li>
            <li>Vérificateur automatique des adresses courriel en double</li>
            <li>
              Double processus de validation des inscriptions à la liste
              d'inclusion
            </li>
            <li>
              Créez des fiches individuellement ou utilisez l'assistant
              importation-exportation pour ajouter une liste de contacts à votre
              carnet
            </li>
            <li>
              Ajoutez, éditez ou supprimez facilement des fiches avec nos outils
              conviviaux
            </li>
            <li>
              Créez des listes d'envoi associées à des campagnes particulières
            </li>
            <li>
              Obtenez des rapports détaillés sur le nombre total de courriels
              envoyés et ouverts, les messages retournés et les désinscriptions
            </li>
            <li>Soyez informé des liens consultés</li>
            <li>Rapports rendus au format Microsoft Excel ou PDF</li>
            <li>Et plus encore...</li>
          </ul>
        </div>
      </div>
    </div>
  );
}
